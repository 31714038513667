<style lang="scss">
/* element样式重置 start */
/* 去掉input尾部上下小箭头 start */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -o-appearance: none !important;
    -ms-appearance: none !important;
    appearance: none !important;
    margin: 0;
}
input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    -o-appearance: textfield;
    -ms-appearance: textfield;
    appearance: textfield;
}
/* 去掉input尾部上下小箭头 end */
</style>
<style lang="scss" scoped>
@import "@/assets/prettyui/slider.scss";
.main {
    position: absolute;
    width: 100%;
    top: 54px;
    bottom: 46px;
}
.login-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    padding: 30px;
    z-index: 9;
}
a {
    text-decoration: none;
    color: #000000;
}
footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    color: #ccc;
}
</style>

<template>
    <div>
        <Header />
        <div class="main">
            <div class="login-box">
                <el-form :model="user" status-icon :rules="rules" ref="elForm" label-width="" style="margin-right: 30px; margin-top: 30px">
                    <el-form-item label="" prop="phone">
                        <i class="el-icon-mobile-phone" style="margin-left: 30px; color: #777; font-size: x-large; vertical-align: middle"></i>
                        <el-input
                            type="number"
                            v-model.number="user.phone"
                            placeholder="请输入手机号"
                            @mousewheel.native.prevent
                            style="width: 250px; margin-left: 10px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="password">
                        <i class="el-icon-lock" style="margin-left: 30px; color: #777; font-size: x-large; vertical-align: middle"></i>
                        <el-input
                            type="password"
                            v-model="user.password"
                            placeholder="请输入密码"
                            show-password
                            style="width: 250px; margin-left: 10px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="" style="text-align: left">
                        <!-- <el-input v-model="user.verifyCode" placeholder="请输入验证码" style="width: 120px;"></el-input>
						<el-tooltip class="item" effect="dark" content="换一张" placement="bottom">
							<img :src="apiServer + '/api/v1/pub/verify_code/get'" id="kaptcha" style="width:100px;height:40px;" />
							<span style="padding: 10px 20px;background-color: #ccc;border-radius: 2px;margin: 6px;">a s 5 6</span>
						</el-tooltip> -->
                        <span style="float: left">拖动验证</span>
                        <div class="form-inline-input" style="margin-left: 8px">
                            <div class="code-box" id="code-box">
                                <input type="text" v-model="user.slider" name="code" class="code-input" />
                                <p></p>
                                <span>></span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item style="padding-left: 64px">
                        <div style="text-align: left">
                            <el-checkbox v-model="autoLogin"></el-checkbox>
                            <span style="margin-left: 4px">7天内免登录</span>
                        </div>
                        <div><el-button type="primary" @click="submitLoginForm('elForm')" style="width: 250px">登录</el-button></div>
                        <!-- <a href="/register" style="float: left">去注册账号</a> -->
                        <!-- <a href="/reset_password" style="float: right">忘记密码</a> -->
                        <!-- <a href="/reset_password" style="float: left">忘记密码</a> -->
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <footer>
            <div style="text-align: center; margin: 10px; color: #666">
                <p>注册功能暂不对外开放......请谅解 (￣▽￣")</p>
            </div>
        </footer>
    </div>
</template>

<script>
import Header from "@/views/pc/common/Header.vue";
import { apiLogin } from "@/api/public/user.js";
import Base from "@/base.js";
import slider from "@/assets/prettyui/slider.js";

export default {
    //注册组件
    components: {
        Header
    },
    data() {
        var checkPhone = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("手机号不能为空"));
            } else if (!Number.isInteger(value)) {
                callback(new Error("请输入数字值"));
            } else {
                callback();
            }
        };
        var validatePassword = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                callback();
            }
        };
        return {
            apiServer: Base.apiServer,
            autoLogin: true,
            user: {
                phone: "",
                password: ""
            },
            rules: {
                phone: [{ validator: checkPhone, trigger: "blur" }],
                password: [{ validator: validatePassword, trigger: "blur" }]
            }
        };
    },
    methods: {
        submitLoginForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    //console.log("user:", this.user);
                    let box = document.querySelector("#code-box");
                    let progress = box.querySelector("p");
                    if (progress.innerText != "验证成功") {
                        this.$message.error("请拖动滑块验证");
                        return;
                    }
                    let formData = new FormData();
                    formData.append("phone", this.user.phone);
                    formData.append("password", this.user.password);
                    //调用登录接口
                    apiLogin(formData).then((response) => {
                        if (response.data.code === 200) {
                            this.$message({
                                message: "登录成功！",
                                type: "success"
                            });
                            // console.log(response.data.data);
                            let user = response.data.data.user;
                            let token = response.data.data.token;

                            if (this.autoLogin) {
                                //长期保存
                                localStorage.setItem("token", token);
                                localStorage.setItem("user", JSON.stringify(user));
                            } else {
                                //浏览器关闭则失效
                                sessionStorage.setItem("token", token);
                                sessionStorage.setItem("user", JSON.stringify(user));
                            }
                            //通过store共享
                            this.$store.dispatch("setToken", token);
                            this.$store.dispatch("setUser", JSON.stringify(user));

                            //console.log(this.$route.query.target);
                            if (this.$route.query.target) {
                                //若携带target，则跳转到target
                                location.href = this.$route.query.target;
                            } else {
                                //跳转到后台首页
                                this.$router.push({ path: "/private/home" });
                            }
                        } else {
                            this.$message.error(response.data.message);
                            console.log("response>>", response);
                            slider.moveCode(true).resetCode();
                            progress.innerText = "";
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        sliderHandler() {
            let get = slider.moveCode(true);
            //console.log("get:", get);

            //code是后台传入的验证字符串
            //var code = "jsaidaisd656";
            //var codeFn = new moveCode(code);

            //获取当前的code值
            //console.log(codeFn.getCode());

            //改变code值
            //code = '46asd546as5';
            //codeFn.setCode(code);

            //重置为初始状态
            //codeFn.resetCode();
        }
    },
    mounted() {
        window.addEventListener("load", this.sliderHandler, true);
    },
    destroyed() {
        //销毁监听,否则监听会一直存在,
        window.removeEventListener("load", this.sliderHandler, true);
    }
};
</script>
